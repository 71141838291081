import { render, staticRenderFns } from "./SPARQLGen.vue?vue&type=template&id=99c93464&scoped=true&"
import script from "./SPARQLGen.vue?vue&type=script&lang=js&"
export * from "./SPARQLGen.vue?vue&type=script&lang=js&"
import style0 from "./SPARQLGen.vue?vue&type=style&index=0&id=99c93464&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99c93464",
  null
  
)

export default component.exports