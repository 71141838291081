<template>
  <v-main>
    <l-draw-toolbar position="topright"></l-draw-toolbar>
    <h1 class="halamanJudul">SPARQL For Mobility Data Warehouse Generator</h1>

    <v-row no-gutters style="margin-top: 12px">
      <v-col cols="12">
        <v-card style="width: 100%; height: 500px">
          <l-map
            ref="map"
            :zoom="zoom"
            :center="center"
            style="height: 100%; width: 100%; z-index: 0"
          >
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-polygon
              v-for="item in layers"
              :key="item.coords"
              :lat-lngs="item.coords"
              :color="item.warna"
            >
            </l-polygon>
            <l-polygon
              v-for="(item, index) in bloks"
              :key="item.coords"
              :lat-lngs="item.coords"
              :color="item.warna"
            >
              <l-popup>
                <v-row>
                  <v-col>
                    <h1>Koordinat Blok {{ index + 1 }}</h1>
                  </v-col>
                </v-row>
                <v-textarea
                  name="input-7-1"
                  filled
                  readonly
                  :value="makeCaption(item)"
                  v-model="captionmaps"
                  width="100"
                ></v-textarea>
                <v-btn
                  color="green"
                  style="margin-top: -15px"
                  @click="toString(item.coordsFull)"
                >
                  <h1 style="color: aliceblue; font-size: 15px">
                    Add to Query
                  </h1>
                </v-btn>
              </l-popup>
            </l-polygon>
            <l-polyline
              v-for="item in getLongLat"
              :key="item.coords"
              :lat-lngs="item.coords"
              :color="item.warna"
            >
            </l-polyline>
            <l-marker
              v-for="item in tphtampung"
              :key="item"
              :lat-lng="item"
            ></l-marker>

            <l-icon-default :image-path="path" />
          </l-map>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters style="margin-top: 12px">
      <v-col cols="4">
        <v-expansion-panels class="pa-2" outlined tile style="margin-top: 15px">
          <v-expansion-panel>
            <v-expansion-panel-header> Measure </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-treeview
                v-model="selection0"
                :items="measure"
                selected-color="indigo"
                selectable
                return-object
              >
              </v-treeview>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel style="margin-top: 10px">
            <v-expansion-panel-header> Dimension </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-treeview
                v-model="selection1"
                :items="dimension"
                selected-color="indigo"
                selectable
                return-object
              >
              </v-treeview>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel style="margin-top: 10px" :key="panelKey">
            <v-expansion-panel-header>
              Aggregat Function
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-treeview
                v-model="selection2"
                :items="agregate"
                selected-color="indigo"
                selectable
                return-object
              ></v-treeview>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel style="margin-top: 10px" :key="panelKey">
            <v-expansion-panel-header> Function </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-treeview
                v-model="selection3"
                :items="Functionitem"
                selected-color="indigo"
                selectable
                return-object
              ></v-treeview>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel style="margin-top: 10px">
            <v-expansion-panel-header>
              Spatial Function
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-treeview
                v-model="selection4"
                :items="spasialFunction"
                selected-color="indigo"
                selectable
                return-object
              ></v-treeview>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <v-col cols="8">
        <v-card>
          <v-container fluid>
            <h1 style="color: black; font-size: 15px">SPARQL</h1>
            <!-- http://localhost:7200/repositories/FFB -->
            <p class="endpointCaption">
              Endpoint :
              <a href="http://lod.if.fti.uajy.ac.id:7200/repositories/FFB">
                http://lod.if.fti.uajy.ac.id:7200/repositories/FFB
              </a>
            </p>
            <p class="warningCaption">
              make sure you activate the Allow CORS plugin in your browser !
            </p>
            <v-textarea
              id="genSparql"
              name="input-7-1"
              filled
              auto-grow
              v-model="content"
            ></v-textarea>
            <h1
              style="
                color: red;
                font-size: 10px;
                margin-top: -20px;
                margin-bottom: 20px;
              "
            >
              {{ this.errorMassage }}
            </h1>
            <v-btn color="green" @click="getUrl()">
              <h1 style="color: aliceblue; font-size: 15px">Execute</h1>
            </v-btn>
            <v-btn color="blue" @click="clearData()" style="margin-left: 10px">
              <h1 style="color: aliceblue; font-size: 15px">Clear</h1>
            </v-btn>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <div id="jsonResponse" style="margin-top: 20px">
      <v-row>
        <v-col>
          <v-card>
            <v-container fluid>
              <h1 style="color: black; font-size: 15px">JSON Response</h1>
              <v-textarea
                id="textbox"
                ref="JSONResponse"
                name="input-7-1"
                filled
                readonly
                v-model="listDataString"
              ></v-textarea>
              <v-btn color="green" @click="cetakTxt()">
                <h1 style="color: aliceblue; font-size: 15px">Save</h1>
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-container fluid>
              <h1 style="color: black; font-size: 15px">XML Response</h1>
              <v-textarea
                id="textbox"
                ref="XMLResponse"
                name="input-7-1"
                filled
                readonly
                v-model="xmlString"
              ></v-textarea>
              <v-btn color="green" @click="cetakXML()">
                <h1 style="color: aliceblue; font-size: 15px">Save</h1>
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col>
        <v-card>
          <v-container fluid>
            <h1 style="color: black; font-size: 15px">Show Results</h1>
            <v-row>
              <v-col>
                <v-btn
                  color="green"
                  style="width: 100%"
                  @click="showTable = !showTable"
                  ><h1 style="color: aliceblue; font-size: 15px">
                    {{ showTable ? "Show Table" : "Hide Table" }}
                  </h1>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <div id="tabelku" v-show="!showTable">
      <v-row>
        <v-col>
          <h1 class="halamanJudul">Query Result Table</h1>
          <v-card style="margin-top: 20px">
            <v-data-table
              :headers="headers"
              :items="dataSparQL"
              :search="search"
              :items-per-page="5"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>
  
  <script>
import { Icon } from "leaflet";
import axios from "axios";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import {
  LMap,
  LTileLayer,
  LMarker,
  LCircle,
  LRectangle,
  LPolygon,
  LPolyline,
  LPopup,
} from "vue2-leaflet";
import { saveAs } from "file-saver";
import LDrawToolbar from "vue2-leaflet-draw-toolbar";
export default {
  components: { LDrawToolbar },
  name: "app",
  data() {
    return {
      errorMassage: "",
      sectionSkrg: "",
      saveAs,
      xmlString: "",

      measureName: [],

      captionMaps: "",
      captionmapsAfdeling: "",
      //////////////////////// new developlment /////////////////
      clearFlag: false,
      panelKey: 0,
      koorDistribusi: [],
      longlatKoorDistribusi: [],
      getLongLat: [],

      //////////////////////// CUSTOME Polygone Gen //////////////
      json: "",
      status: "",
      filter: ``,
      tampungGeo: "",
      ///////////////////////////////////////////////////////
      measureTempt: [],
      tampungmeasureskrggabungbaru: "",
      tampungmeasure: ``,
      tampungText: ``,
      tampungTextNew: ``,
      tampungfunction: ``,
      tampungfunction2: ``,
      tampungfunctionIncrement: ``,
      tampungmeasureforfunction: ``,

      measurename: ``,
      measureGabung: ``,
      arrmeasureGabung: [],
      querymeasure: ``,
      querymeasuregabung: ``,
      arrquerymeasuregabung: [],
      tampungmeasureskrg: [],
      tampungmeasureskrgForFunction: [],

      dimensionatrbSkrg: ``,
      dimensionkeyTampung: ``,
      dimensionkey: ``,
      dimensionkeygabung: ``,
      arrdimensionkey: [],

      dimatributTampung: [],
      dimnameTampung: ``,
      dimatribut: ``,
      dimatributgabung: ``,
      arrdimatribut: [],
      arrdimensiongabungAll: [],
      queryDimensionffb: ``,
      querydimensiongabung: [],

      queryqb4oAfd: ``,
      queryqb4ogabungAfd: [],
      queryqb4oBlck: ``,
      queryqb4ogabungBlck: [],
      queryqb4oTime: ``,
      queryqb4ogabungTime: [],
      queryqb4oFcp: ``,
      queryqb4ogabungFcp: [],
      queryqb4oPlant: ``,
      queryqb4ogabungPlant: [],
      queryqb4oTruck: ``,
      queryqb4ogabungTruck: [],

      queryincrementAfd: ``,
      arrqueryincrementgabungAfd: [],
      queryincrementBlck: ``,
      arrqueryincrementgabungBlck: [],
      queryincrementTime: ``,
      arrqueryincrementgabungTime: [],
      queryincrementFcp: ``,
      arrqueryincrementgabungFcp: [],
      queryincrementPlant: ``,
      arrqueryincrementgabungPlant: [],
      queryincrementTruck: ``,
      arrqueryincrementgabungTruck: [],

      queryqb4oAllAfd: [],
      queryqb4oAllBlck: [],
      queryqb4oAllTime: [],
      queryqb4oAllFcp: [],
      queryqb4oAllPlant: [],
      queryqb4oAllTruck: [],

      queryqb40AllTampung: ``,
      queryqb40AllGabung: ``,
      arrqueryqb4oAll: [],

      newFunction: false,
      oldFunction: false,
      sementara: "",
      sementara2: "",
      content: "",
      dataSparQL: [],
      headers: [],
      listDataString: ``,
      listData: [],

      showTable: true,
      showmap: false,
      graph: true,
      LMap,
      LTileLayer,
      LMarker,
      LCircle,
      LRectangle,
      LPolygon,
      LPolyline,
      LPopup,
      koordinatTest: [],
      zoom: 15,
      Koordinat: [],
      Blokku: [],
      Markerku: [],
      path: "/images/",
      center: [-7.2384181, 110.4398232],

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>,Map data &copy; <a href="https://carto.com/attribution">@protimo</a>',
      map: null,
      bounds: null,
      tileLayer: null,

      test: [],
      layers: [],

      bloktampung: [],
      bloks: [],
      bloksAll: [],

      tphtampung: [],

      tphs: [],

      markers: [
        {
          position: { lat: -7.7612784, lng: 110.4246399 },
        },

        {
          position: { lat: -7.7612784, lng: 110.4246399 },
        },

        {
          position: { lat: -7.7612784, lng: 110.4246399 },
        },
      ],

      paths: [
        { lat: 25.774, lng: -80.19 },
        { lat: 18.466, lng: -66.118 },
        { lat: 32.321, lng: -64.757 },
      ],

      active: [],
      // chartData: {
      //   "2017-05-13": 2,
      //   "2017-05-14": 4,
      //   "2017-05-15": 5,
      //   "2017-05-16": 6,
      //   "2017-05-17": 2,
      //   "2017-05-18": 1,
      //   "2017-05-19": 3,
      //   "2017-05-20": 4,
      // },
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      value: [],
      selectionType: "independent",
      valueTextArea: ["SELECT"],
      selection0: [],
      selection1: [],
      selection2: [],
      selection3: [],
      selection4: [],
      sparqlTable: [
        {
          S: 100,
          P: 159,
          O: 150,
        },
      ],

      // HANYA CONTOH
      // isLength: [],

      breweries: [],
      isLoading: false,
      tree: [],
      types: [],
    };
  },
  computed: {
    measure() {
      return [
        {
          id: 1,
          name: "Load",
          query: `?load`,
        },
        {
          id: 167,
          name: "Route",
          query: `?droute`,
        },
        {
          id: 168,
          name: "Trajectory",
          query: `?trajectory`,
        },
        {
          id: 169,
          name: "Distance",
          query: `?distance`,
        },
        {
          id: 170,
          name: "Duration",
          query: `?duration`,
        },
      ];
    },

    dimension() {
      return [
        {
          id: 2,
          name: "Dim-Afdeling",
          children: [
            {
              id: 5,
              dimensioname: "afdeling",
              name: "afdelingName",
              type: "atrb",
              query: `?afdelingname`,
              key: `?afdelingKey`,
            },
            {
              id: 6,
              dimensioname: "afdeling",
              name: "afdelingGeo",
              type: "atrb",
              query: `?afdelinggeo`,
              key: `?afdelingKey`,
            },
          ],
        },
        {
          id: 7,
          name: "Dim-Block",
          children: [
            {
              id: 10,
              dimensioname: "block",
              name: "blockName",
              type: "atrb",
              query: `?blockname`,
              key: `?blockKey`,
            },
            {
              id: 11,
              dimensioname: "block",
              name: "blockGeo",
              type: "atrb",
              query: `?blockgeo`,
              key: `?blockKey`,
            },
          ],
        },
        {
          id: 12,
          name: "Dim-Date",
          children: [
            {
              id: 14,
              dimensioname: "date",
              name: "date",
              type: "atrb",
              query: `?date`,
              key: `?timeKey`,
            },
            {
              id: 15,
              dimensioname: "date",
              name: "epoch",
              type: "atrb",
              query: `?epoch`,
              key: `?timeKey`,
            },
            {
              id: 16,
              dimensioname: "date",
              name: "day_suffix",
              type: "atrb",
              query: `?day_suffix`,
              key: `?timeKey`,
            },
            {
              id: 17,
              dimensioname: "date",
              name: "day_name",
              type: "atrb",
              query: `?day_name`,
              key: `?timeKey`,
            },
            {
              id: 18,
              dimensioname: "date",
              name: "day_of_week",
              type: "atrb",
              query: `?day_of_week`,
              key: `?timeKey`,
            },
            {
              id: 19,
              dimensioname: "date",
              name: "month",
              type: "atrb",
              query: `?month`,
              key: `?timeKey`,
            },
            {
              id: 20,
              dimensioname: "date",
              name: "quarter",
              type: "atrb",
              query: `?quarter`,
              key: `?timeKey`,
            },
            {
              id: 30,
              dimensioname: "date",
              name: "year",
              type: "atrb",
              query: `?year`,
              key: `?timeKey`,
            },
          ],
        },
        {
          id: 42,
          name: "Dim-FCP",
          children: [
            // { id: 43,dimensioname: "fcp", name: "fcpkey",type: 'key',query:`?date`,key:`?fcpkey` },
            {
              id: 45,
              dimensioname: "fcp",
              name: "fcpname",
              type: "atrb",
              query: `?fcpname`,
              key: `?fcpKey`,
            },
            {
              id: 46,
              dimensioname: "fcp",
              name: "fcpgeo",
              type: "atrb",
              query: `?fcpgeo`,
              key: `?fcpKey`,
            },
          ],
        },
        {
          id: 47,
          name: "Dim-Plantation",
          children: [
            // { id: 48, dimensioname: "plant", name: "plantationKey",type: 'key',query:`?plantationKey`,key:`?plantationKey` },
            {
              id: 49,
              dimensioname: "plantation",
              name: "plantationName",
              type: "atrb",
              query: `?plantationName`,
              key: `?plantationKey`,
            },
            {
              id: 50,
              dimensioname: "plantation",
              name: "plantationGeo",
              type: "atrb",
              query: `?plantationGeo`,
              key: `?plantationKey`,
            },
            {
              id: 51,
              dimensioname: "plantation",
              name: "PMOGeo",
              type: "atrb",
              query: `?PMOGeo`,
              key: `?plantationKey`,
            },
            // { id: 52, dimensioname: "plantation", name: "gategeo",type: 'atrb',query:`?gategeo`,key:`?plantationKey` },
          ],
        },
        {
          id: 53,
          name: "Dim-Truck",
          children: [
            // { id: 54, dimensioname: "truck", name: "truckKey", type: 'key', query: `?truckKey`,key:`?truckKey` },
            {
              id: 55,
              dimensioname: "truck",
              name: "maxLoad",
              type: "atrb",
              query: `?maxload`,
              key: `?truckKey`,
            },
            {
              id: 56,
              dimensioname: "truck",
              name: "ownerName",
              type: "atrb",
              query: `?oName`,
              key: `?truckKey`,
            },
          ],
        },
      ];
    },

    agregate() {
      return [
        {
          id: 58,
          name: "COUNT",
          children: [],
        },
        {
          id: 66,
          name: "AVG",
          children: [],
        },
        {
          id: 74,
          name: "SUM",
          children: [],
        },
        {
          id: 82,
          name: "MIN",
          children: [],
        },
        {
          id: 90,
          name: "MAX",
          children: [],
        },
      ];
    },

    Functionitem() {
      return [
        {
          id: 162,
          name: "length",
          children: [],
        },
        {
          id: 163,
          name: "Duration",
          children: [],
        },
        {
          id: 166,
          name: "TSpeed",
          children: [],
        },
        {
          id: 98,
          name: "TWAvg",
          children: [],
        },
      ];
    },

    spasialFunction() {
      return [
        {
          id: 171,
          name: "sfWithin",
          query: ``,
        },
      ];
    },

    nice() {
      return [
        {
          id: 1,
          name: "AVG",
          children: [
            { id: 2, name: "length", queries: "" },
            { id: 3, name: "timespan", queries: "" },
            { id: 4, name: "speed", queries: "" },
            { id: 5, name: "trip", queries: "" },
            { id: 6, name: "load", queries: "" },
          ],
        },
        {
          id: 7,
          name: "SUM",
          children: [
            { id: 8, name: "length", queries: "" },
            { id: 9, name: "timespan", queries: "" },
            { id: 10, name: "speed", queries: "" },
            { id: 11, name: "trip", queries: "" },
            { id: 12, name: "load", queries: "" },
          ],
        },
      ];
    },
  },
  watch: {
    content: function () {
      this.ifContentNull();
    },

    selection0: function (val) {
      this.panelKey += 1;
      this.getMeasure(val);
    },
    selection1: function (val) {
      this.getActiveValue1(val);
    },

    selection2: function (val) {
      this.getActiveValue2(val);
    },

    selection3: function (val) {
      this.panelKey += 1;
      this.getActiveValue3(val);
    },

    selection4: function (val) {
      this.getSpatialFunction(val);
    },
  },

  methods: {
    updatePanelKey() {},
    toString(koord) {
      if (this.filter != "") {
        this.json = koord;
        this.$vuetify.goTo("#genSparql");
        this.queryAll();
      } else {
        let query = `PREFIX jsfn: <http://www.ontotext.com/js#>\nPREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\nPREFIX qb: <http://purl.org/linked-data/cube#>\nPREFIX ffb: <http://www.uajy.ac.id/ffbi/>\nPREFIX qb4o: <http://www.w3.org/2001/XMLSchema#>\nPREFIX geof: <http://www.opengis.net/def/function/geosparql/>\nPREFIX ffbi: <http://www.uajy.ac.id/ffbi/instance#>\n`;
        query += `SELECT ?oName (AVG(jsfn:Duration(jsfn:insideGeometry(?droute,?afdelingGeo))) as ?InsideAfdelingAlfa)\n`;
        query += `WHERE {\n`;
        query += `?obs rdf:type qb:Observation; \n`;
        query += `ffb:truckKey ?truckKey ;
          ffb:dateKey ?dateKey ;
          ffb:Route ?droute ;
          ffb:plantationKey ?plantationKey .
          ?dateKey qb4o:memberOf ffb:date;
          ffb:quarter ?quarter .
          ?plantationKey qb4o:memberOf ffb:plantation ;
          ffb:plantationGeo ?plantationGeo .
          ?afdelingKey #qb4o:memberOf ffb:afdeling ;
          ffb:afdelingGeo ?afdelingGeo ;
          ffb:afdelingName 'Afdeling Alfa' .
          ?truckKey #qb4o:memberOf ffb:Truck;
          ffb:ownerName ?oName .
          FILTER (geof:sfWithin(?afdelingGeo,"${koord}")) 
          } 
          GROUP BY ?oName`;
        this.content = "";
        this.content = query;
        this.$vuetify.goTo("#genSparql");
      }
    },
    ifContentNull() {
      // if(this.clearFlag == true){
      //   this.Functionitem.children = [];
      //   this.agregate.children = [];
      //   this.clearFlag = false
      // }
    },
    getUrl() {
      this.showTable = false;
      let headers = {
        Accept: "application/sparql-results+json",
      };
      let myquery = this.content;

      axios
        .get("http://lod.if.fti.uajy.ac.id:7200/repositories/FFB", {
          /// Untuk GraphDB umum ///
          //////menggunakan moesif untuk skip cors//////
          params: {
            query: myquery,
          },
          headers,
          withCredentials: true,
          crossdomain: true,
          mode: "cors",
        })
        .then((response) => {
          this.loading = false;
          this.sementara = response.data;
          this.headers = [];
          this.dataSparQL = [];

          ///////// MAKE JSON RESPONSE ////////////////////
          this.listDataString = JSON.stringify(response.data, null, "\t");
          this.listData = response.data;

          ///////////// MAKE CONVERT XML //////////////////////
          let json = JSON.parse(this.listDataString);

          let xml = `<?xml version='1.0' encoding='UTF-8'?>\n`;
          xml += `<sparql xmlns='http://www.w3.org/2005/sparql-results#'>\n`;
          xml += "    <head>\n";
          for (const key of json.head.vars) {
            xml += `          <variable name='${key}'/>\n`;
          }
          xml += "    <head>\n";
          xml += "    <results>\n";

          for (const binding of json.results.bindings) {
            xml += "          <result>\n";
            for (const key in binding) {
              xml += `              <binding name='${key}'>\n`;
              xml += `                      <${binding[key].type}>${binding[key].value}</${binding[key].type}>\n`;
              xml += "              </binding>\n";
            }
            xml += "          </result>\n";
          }
          xml += "    </results>\n";
          xml += "</sparql>";
          this.xmlString = xml;

          ///////////////// MAKE TABLE /////////////////////////////
          for (var a = 0; a < response.data.head.vars.length; a++) {
            var test = {
              text: String(response.data.head.vars[a]),
              value: String(response.data.head.vars[a]),
            };
            this.headers.push(test);
          }
          for (var b = 0; b < response.data.results.bindings.length; b++) {
            var json_temp = "{";
            for (var c = 0; c < this.headers.length; c++) {
              if (this.headers[c].text in response.data.results.bindings[b]) {
                this.sementara2 = "a";
                json_temp = json_temp.concat(
                  '"' +
                    String(this.headers[c].text) +
                    '":"' +
                    response.data.results.bindings[b][this.headers[c].text]
                      .value +
                    '",'
                );
              } else {
                this.sementara2 = "b";
                json_temp = json_temp.concat(
                  '"' + String(this.headers[c].text) + '":" ",'
                );
              }
            }
            json_temp = json_temp.slice(0, -1);
            json_temp = json_temp.concat("}");

            this.dataSparQL.push(JSON.parse(json_temp));
          }
          this.sementara2 = json_temp;
          this.$vuetify.goTo("#tabelku");
          this.errorMassage = "";
        })
        .catch((error) => {
          this.errorMassage = error.response.data;
        });
    },

    clearData() {
      this.koorDistribusi = [];
      this.longlatKoorDistribusi = [];
      this.sectionSkrg = "";
      this.Functionitem.forEach((obj) => {
        obj.children.splice(0, obj.children.length);
      });
      this.agregate.forEach((obj) => {
        obj.children.splice(0, obj.children.length);
      });
      this.json = "";
      this.status = "";
      this.filter = ``;
      this.tampungGeo = "";
      this.measureTempt = [];
      this.tampungmeasureskrggabungbaru = "";
      this.tampungmeasure = ``;
      this.tampungText = ``;
      this.tampungTextNew = ``;
      this.tampungfunction = ``;
      this.tampungfunction2 = ``;
      this.tampungfunctionIncrement = ``;
      this.tampungmeasureforfunction = ``;
      this.measurename = ``;
      this.measureGabung = ``;
      this.arrmeasureGabung = [];
      this.querymeasure = ``;
      this.querymeasuregabung = ``;
      this.arrquerymeasuregabung = [];
      this.tampungmeasureskrg = [];
      this.tampungmeasureskrgForFunction = [];
      this.dimensionatrbSkrg = ``;
      this.dimensionkeyTampung = ``;
      this.dimensionkey = ``;
      this.dimensionkeygabung = ``;
      this.arrdimensionkey = [];
      this.dimatributTampung = [];
      this.dimnameTampung = ``;
      this.dimatribut = ``;
      this.dimatributgabung = ``;
      this.arrdimatribut = [];
      this.arrdimensiongabungAll = [];
      this.queryDimensionffb = ``;
      this.querydimensiongabung = [];
      this.queryqb4oAfd = ``;
      this.queryqb4ogabungAfd = [];
      this.queryqb4oBlck = ``;
      this.queryqb4ogabungBlck = [];
      this.queryqb4oTime = ``;
      this.queryqb4ogabungTime = [];
      this.queryqb4oFcp = ``;
      this.queryqb4ogabungFcp = [];
      this.queryqb4oPlant = ``;
      this.queryqb4ogabungPlant = [];
      this.queryqb4oTruck = ``;
      this.queryqb4ogabungTruck = [];
      this.queryincrementAfd = ``;
      this.arrqueryincrementgabungAfd = [];
      this.queryincrementBlck = ``;
      this.arrqueryincrementgabungBlck = [];
      this.queryincrementTime = ``;
      this.arrqueryincrementgabungTime = [];
      this.queryincrementFcp = ``;
      this.arrqueryincrementgabungFcp = [];
      this.queryincrementPlant = ``;
      this.arrqueryincrementgabungPlant = [];
      this.queryincrementTruck = ``;
      this.arrqueryincrementgabungTruck = [];
      this.queryqb4oAllAfd = [];
      this.queryqb4oAllBlck = [];
      this.queryqb4oAllTime = [];
      this.queryqb4oAllFcp = [];
      this.queryqb4oAllPlant = [];
      this.queryqb4oAllTruck = [];
      this.queryqb40AllTampung = ``;
      this.queryqb40AllGabung = ``;
      this.arrqueryqb4oAll = [];
      this.newFunction = false;
      this.oldFunction = false;
      this.sementara = "";
      this.sementara2 = "";
      this.content = ``;
      this.dataSparQL = [];
      this.headers = [];
      this.listDataString = ``;
      this.listData = [];
      this.xmlString = ``;
      this.showTable = true;
      this.showmap = false;
      this.graph = true;
      this.koordinatTest = [];
      this.zoom = 15;
      this.map = null;
      this.bounds = null;
      this.tileLayer = null;
      this.test = [];
      this.bloktampung = [];
      this.bloksAll = [];
      this.tphs = [];
      this.markers = [];
      this.paths = [];
      this.active = [];
      this.items = [];
      this.value = [];
      this.selectionType = "";
      this.valueTextArea = [];
      this.selection0 = [];
      this.selection1 = [];
      this.selection2 = [];
      this.selection3 = [];
      this.selection4 = [];
      this.sparqlTable = [];
      this.breweries = [];
      this.isLoading = false;
      this.tree = [];
      this.types = [];

      // this.clearFlag = true;
      console.log(this.selection0);
      console.log(this.selection4);

      console.log(this.Functionitem);
      console.log(this.agregate);
      this.$vuetify.goTo(0);
      this.queryAll();
    },

    cetakXML() {
      if (this.xmlString != ``) {
        var file = new Blob([this.xmlString], { type: "octet/stream" });
        var anchor = document.createElement("a");
        var url = window.URL.createObjectURL(file);
        anchor.href = url;
        anchor.download = "SPARQLXmlResult.txt";
        anchor.click();
        window.URL.revokeObjectURL(url);
        alert("Successfully save json response !");
      } else {
        alert("Empty XML Response!");
      }
    },
    cetakTxt() {
      if (this.listDataString != ``) {
        var file = new Blob([this.listDataString], { type: "octet/stream" });
        var anchor = document.createElement("a");
        var url = window.URL.createObjectURL(file);
        anchor.href = url;
        anchor.download = "SPARQLJsonResult.txt";
        anchor.click();
        window.URL.revokeObjectURL(url);
        alert("Successfully save json response !");
      } else {
        alert("Empty JSON Response!");
      }
    },
    readAfdeling() {
      var url = this.$api + "/afdeling";
      this.$http.get(url).then((response) => {
        this.Koordinat = response.data.data;
        for (var i = 0; i < this.Koordinat.length; i++) {
          let koord = JSON.parse(this.Koordinat[i].koordinat);
          // let koordFull = JSON.parse(this.Koordinat[i].koordinatFull)
          this.layers.push({
            deskripsi: this.Koordinat[i].deskripsi,
            id_afdeling: this.Koordinat[i].id_afdeling,
            id_kebun: this.Koordinat[i].id_kebun,
            id_user: this.Koordinat[i].id_user,
            jumlah_pohon: this.Koordinat[i].jumlah_pohon,
            coords: koord,
            // coordsFull: this.Koordinat[i].koordinatFull,
            luas: this.Koordinat[i].luas,
            warna: this.Koordinat[i].warna,
          });
        }
      });
    },

    readBlok() {
      var url = this.$api + "/blokFull";
      this.$http.get(url).then((response) => {
        this.Blokku = response.data.data;
        this.BlokkuJson = JSON.stringify(response.data, null, "\t");

        for (var i = 0; i < this.Blokku.length; i++) {
          let blok = JSON.parse(this.Blokku[i].koordinat);
          this.bloks.push({
            deskripsi: this.Blokku[i].deskripsi,
            id_afdeling: this.Blokku[i].id_afdeling,
            id_blok: this.Blokku[i].id_blok,
            jumlah_pohon: this.Blokku[i].jumlah_pohon,
            coords: blok,
            coordsFull: this.Blokku[i].koordinatFull,
          });
        }
      });
    },

    readKoorDistribusi() {
      var url = this.$api + "/koorDistribusi";
      this.$http.get(url).then((response) => {
        this.koorDistribusi = response.data.data;
        for (var i = 0; i < this.koorDistribusi.length; i++) {
          var coords = [];
          for (
            var j = 0;
            j < JSON.parse(this.koorDistribusi[i].longlat).length;
            j++
          ) {
            coords.push(JSON.parse(this.koorDistribusi[i].longlat)[j]);
          }
          this.getLongLat.push({
            id: i,
            coords: coords,
            warna: `hsl(${360 * Math.random()}, 50%, 50%)`,
          });
        }
      });
    },

    readMarker() {
      var url = this.$api + "/tph";
      this.$http.get(url).then((response) => {
        this.Markerku = response.data.data;
        for (var i = 0; i < this.Markerku.length; i++) {
          this.tphtampung.push(JSON.parse(this.Markerku[i].koordinat));
        }
      });
    },
    makeCaption(item) {
      let caption = `Id Blok : ${item.id_blok}\n`;
      caption += `Id Afdeling : ${item.id_afdeling}\n`;
      caption += `Jumlah Pohon : ${item.jumlah_pohon}\n`;
      caption += `Deskripsi : ${item.deskripsi}\n`;
      caption += `Koordinat : ${item.coordsFull}\n`;
      this.captionmaps = caption;
    },

    makeCaptionAfdeling(item) {
      let captionAf = `Id Afdeling : ${item.id_afdeling}\n`;
      captionAf += `Id Kebun : ${item.id_kebun}\n`;
      captionAf += `Jumlah Pohon : ${item.jumlah_pohon}\n`;
      captionAf += `Luas : ${item.luas}\n`;
      captionAf += `Deskripsi : ${item.deskripsi}\n`;
      captionAf += `Koordinat : ${item.coords}\n`;
      this.captionmapsAfdeling = captionAf;
    },

    queryAll() {
      var prefix = `PREFIX jsfn: <http://www.ontotext.com/js#>\nPREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\nPREFIX qb: <http://purl.org/linked-data/cube#>\nPREFIX ffb: <http://www.uajy.ac.id/ffbi/>\nPREFIX qb4o: <http://www.w3.org/2001/XMLSchema#>\nPREFIX geof: <http://www.opengis.net/def/function/geosparql/>\nPREFIX ffbi: <http://www.uajy.ac.id/ffbi/instance#>\n`;
      var query;
      if (this.content == ``) {
        console.log("if content kosong");
        query = `${prefix}\nSelect * \nwhere{\n   ?s ?p ?o .\n}limit 100`;
        this.content = query;
        console.log(this.content);
      }
      if (this.sectionSkrg == "measure") {
        console.log("Masuk Measure Season");
        if (this.measureTempt.length <= 0) {
          this.content = ``;
          if (this.filter != "") {
            query = `${prefix}\nSelect ${this.arrmeasureGabung}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}.\n  ${this.filter}} `;
            this.content = query;
          } else {
            query = `${prefix}\nSelect ${this.arrmeasureGabung}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}.\n} `;
            this.content = query;
          }
        } else {
          this.arrmeasureGabung.splice(0, this.arrmeasureGabung.length);
          this.arrmeasureGabung.push(this.measureTempt);
          this.content = ``;
          if (this.filter != "") {
            query = `${prefix}\nSelect ${this.arrmeasureGabung}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}.\n  ${this.filter}}`;
            this.content = query;
          } else {
            query = `${prefix}\nSelect ${this.arrmeasureGabung}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}.\n}`;
            this.content = query;
          }
        }
      }
      if (this.sectionSkrg == "dimension") {
        console.log("dimension seson");
        if (this.measureTempt.length <= 0) {
          this.content = query;
          this.content = ``;
          if (this.filter != "") {
            if (this.tampungGeo != "") {
              this.content = ``;
              this.filter = `FILTER (geof:sfWithin(${this.tampungGeo},"${this.json}"))`;
              query = `${prefix}\nSelect ${this.arrmeasureGabung} ${this.arrdimatribut}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}\n   ${this.querydimensiongabung}.\n   ${this.arrqueryqb4oAll}\n  ${this.filter}\n}`;
              this.content = query;
            } else {
              query = `${prefix}\nSelect ${this.arrmeasureGabung} ${this.arrdimatribut}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}\n   ${this.querydimensiongabung}.\n   ${this.arrqueryqb4oAll}\n  ${this.filter}\n}`;
              this.content = query;
            }
          } else {
            query = `${prefix}\nSelect ${this.arrmeasureGabung} ${this.arrdimatribut}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}\n   ${this.querydimensiongabung}.\n   ${this.arrqueryqb4oAll}\n}`;
            this.content = query;
          }
        } else {
          this.arrmeasureGabung.splice(0, this.arrmeasureGabung.length);
          this.arrmeasureGabung.push(this.measureTempt);

          if (this.newFunction === true) {
            this.content = query;
            this.content = ``;
            if (this.filter != "") {
              query = `${prefix}\nSelect ${this.arrmeasureGabung} ${this.arrdimatribut}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}\n   ${this.querydimensiongabung}.\n   ${this.arrqueryqb4oAll}\n  ${this.filter}\n}`;
              this.content = query;
            } else {
              query = `${prefix}\nSelect ${this.arrmeasureGabung} ${this.arrdimatribut}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}\n   ${this.querydimensiongabung}.\n   ${this.arrqueryqb4oAll}\n}`;
              this.content = query;
            }
          } else {
            this.content = query;
            this.content = ``;
            if (this.filter != "") {
              this.filter = `FILTER (geof:sfWithin(${this.tampungGeo},"${this.json}"))`;
              query = `${prefix}\nSelect ${this.arrmeasureGabung} ${this.arrdimatribut}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}\n   ${this.querydimensiongabung}.\n   ${this.arrqueryqb4oAll}\n  ${this.filter}\n} GROUP BY ${this.arrdimatribut}`;
              this.newFunction = false;
              this.content = query;
            } else {
              query = `${prefix}\nSelect ${this.arrmeasureGabung} ${this.arrdimatribut}\nwhere{\n    ?obs rdf:type qb:Observation ;\n    ${this.arrquerymeasuregabung}\n   ${this.querydimensiongabung}.\n   ${this.arrqueryqb4oAll}\n} GROUP BY ${this.arrdimatribut}`;
              this.newFunction = false;
              this.content = query;
            }
          }
        }
      }
    },
    getMeasure(value) {
      if (value.length != 0) {
        for (var i = 0; i < value.length; i++) {
          if (this.tampungmeasureskrg.length <= 0) {
            this.tampungmeasureskrg.push(value[i].query);
            this.tampungmeasureskrgForFunction.push(value[i].query);
            this.measureGabung = `${this.measureGabung} ${value[i].query}`;
            this.querymeasure = `ffb:${value[i].name} ${value[i].query}; `;
            this.querymeasuregabung = `${this.querymeasuregabung} ${this.querymeasure}`;

            this.tampungmeasure = value[i].query;
          }
          if (this.tampungmeasureskrg[i] == value[i].query) {
            continue;
          }
          if (this.tampungmeasureskrg[i] != value[i].query) {
            this.tampungmeasureskrg.push(value[i].query);
            this.tampungmeasureskrgForFunction.push(value[i].query);
            this.measureGabung = `${this.measureGabung} ${value[i].query}`;
            this.querymeasure = `ffb:${value[i].name} ${value[i].query}; `;
            this.querymeasuregabung = `${this.querymeasuregabung} ${this.querymeasure}`;
            this.tampungmeasure = value[i].query;
          }
        }

        if (this.measureGabung != "") {
          if (this.arrmeasureGabung.length <= 0) {
            this.arrmeasureGabung.push(this.measureGabung);
          } else {
            this.arrmeasureGabung.splice(0, this.arrmeasureGabung.length);
            this.arrmeasureGabung.push(this.measureGabung);
          }
        }

        if (this.querymeasuregabung != "") {
          if (this.arrquerymeasuregabung.length <= 0) {
            this.arrquerymeasuregabung.push(this.querymeasuregabung);
          } else {
            this.arrquerymeasuregabung.splice(
              0,
              this.arrquerymeasuregabung.length
            );
            this.arrquerymeasuregabung.push(this.querymeasuregabung);
          }
        }

        for (var ij = 0; ij < this.agregate.length; ij++) {
          if (this.tampungmeasure != "") {
            this.agregate[ij].children.push({
              id: Math.random() + ij * 1000 + 20,
              name: this.tampungmeasure,
              key: this.agregate[ij].name,
            });
          } else {
            this.agregate[ij].children.push({
              id: Math.random() + ij * 1000 + 1,
              name: this.tampungmeasure,
              key: this.agregate[ij].name,
            });
          }
        }

        for (var ijk = 0; ijk < this.Functionitem.length; ijk++) {
          if (this.tampungmeasure != "") {
            this.Functionitem[ijk].children.push({
              id: Math.random() + ijk * 1000 + 21,
              name: this.tampungmeasure,
              key: this.Functionitem[ijk].name,
            });
          } else {
            this.Functionitem[ijk].children.push({
              id: Math.random() + ijk * 1000 + 5,
              name: this.tampungmeasure,
              key: this.Functionitem[ijk].name,
            });
          }
        }
        this.sectionSkrg = "measure";
        this.queryAll();
      }
    },

    // Dimension /////////
    getActiveValue1(value) {
      if (value.length > 0) {
        for (var ii = 0; ii < value.length; ii++) {
          if (value[ii].query == "?afdelinggeo") {
            this.tampungGeo = value[ii].query;
          }
          if (value[ii].query == "?blockgeo") {
            this.tampungGeo = value[ii].query;
          }
          if (value[ii].query == "?plantationGeo") {
            this.tampungGeo = value[ii].query;
          }
          if (this.dimensionkeyTampung == ``) {
            this.dimatributgabung = `${this.dimatributgabung} ${value[ii].query}`;
            this.dimensionkeygabung = `${this.dimensionkeygabung} ${value[ii].key}`;

            if (value[ii].key == `?afdelingKey`) {
              this.queryDimensionffb = `  ffb:afdelingKey ${value[ii].key}; `;
              this.queryqb4oAfd = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
              this.queryincrementAfd = `ffb:${value[ii].name} ${value[ii].query}; `;
              // if(this.queryqb4oAllAfd.length <=0){
              //   this.queryqb40AllGabung=`  ${this.queryqb4oAfd}\n         ${this.queryincrementAfd}. `
              //   this.queryqb4oAllAfd.push(value[ii].key);
              // }else{
              //   this.queryqb40AllGabung=`  ${this.queryqb4oAfd}\n         ${this.queryincrementAfd} `
              //   this.queryqb4oAllAfd.push(value[ii].key);
              // }
              this.queryqb40AllGabung = `  ${this.queryqb4oAfd}\n         ${this.queryincrementAfd} `;
              this.queryqb4oAllAfd.push(value[ii].key);
              this.queryqb40AllTampung = value[ii].key;
            } else if (value[ii].key == `?blockKey`) {
              this.queryDimensionffb = `  ffb:blockKey ${value[ii].key}; `;
              this.queryqb4oBlck = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
              this.queryincrementBlck = `ffb:${value[ii].name} ${value[ii].query}; `;
              this.queryqb40AllGabung = `  ${this.queryqb4oBlck}\n         ${this.queryincrementBlck} `;
              this.queryqb40AllTampung = value[ii].key;
              this.queryqb4oAllBlck.push(value[ii].key);
            } else if (value[ii].key == `?timeKey`) {
              this.queryDimensionffb = `  ffb:dateKey ${value[ii].key}; `;
              this.queryqb4oTime = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
              this.queryincrementTime = `ffb:${value[ii].name} ${value[ii].query}; `;
              this.queryqb40AllGabung = `  ${this.queryqb4oTime}\n         ${this.queryincrementTime} `;
              this.queryqb40AllTampung = value[ii].key;
              this.queryqb4oAllTime.push(value[ii].key);
            } else if (value[ii].key == `?fcpKey`) {
              this.queryDimensionffb = `  ffb:fcpKey ${value[ii].key}; `;
              this.queryqb4oFcp = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
              this.queryincrementFcp = `ffb:${value[ii].name} ${value[ii].query}; `;
              this.queryqb40AllGabung = `  ${this.queryqb4oFcp}\n         ${this.queryincrementFcp} `;
              this.queryqb40AllTampung = value[ii].key;
              this.queryqb4oAllTime.push(value[ii].key);
            } else if (value[ii].key == `?plantationKey`) {
              this.queryDimensionffb = `  ffb:plantationKey ${value[ii].key}; `;
              this.queryqb4oPlant = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
              this.queryincrementPlant = `ffb:${value[ii].name} ${value[ii].query}; `;
              this.queryqb40AllGabung = `  ${this.queryqb4oPlant}\n         ${this.queryincrementPlant} `;
              this.queryqb40AllTampung = value[ii].key;
              this.queryqb4oAllTime.push(value[ii].key);
            } else if (value[ii].key == `?truckKey`) {
              this.queryDimensionffb = `  ffb:truckKey ${value[ii].key}; `;
              this.queryqb4oTruck = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
              this.queryincrementTruck = `ffb:${value[ii].name} ${value[ii].query}; `;
              this.queryqb40AllGabung = `  ${this.queryqb4oTruck}\n         ${this.queryincrementTruck} `;
              this.queryqb40AllTampung = value[ii].key;
              this.queryqb4oAllTime.push(value[ii].key);
            }

            if (this.dimatributTampung.length <= 0) {
              this.dimatributTampung.push(value[ii].query);
              this.dimnameTampung = value[ii].name;
              this.dimensionkeyTampung = value[ii].key;
              break;
            }
          }
          if (this.dimensionkeyTampung == value[ii].key) {
            if (this.dimatributTampung.length <= 0) {
              this.dimatributTampung.push(value[ii].query);
              this.dimnameTampung = value[ii].name;
              this.dimensionkeyTampung = value[ii].key;
              break;
            }
            if (this.dimatributTampung[ii] == value[ii].query) {
              continue;
            }
            if (this.dimatributTampung[ii] != value[ii].query) {
              this.dimatributTampung.push(value[ii].query);

              if (value[ii].key == `?afdelingKey`) {
                this.queryincrementAfd = `${this.queryincrementAfd}\n         ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n         ffb:${value[ii].name} ${value[ii].query};. `;
                this.queryqb40AllTampung = value[ii].key;
                this.queryqb4oAllAfd.push(value[ii].key);
              } else if (value[ii].key == `?blockKey`) {
                this.queryincrementBlck = `${this.queryincrementBlck} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n         ffb:${value[ii].name} ${value[ii].query};. `;
                this.queryqb40AllTampung = value[ii].key;
                this.queryqb4oAllBlck.push(value[ii].key);
              } else if (value[ii].key == `?timeKey`) {
                this.queryincrementTime = `${this.queryincrementTime} ffb:${value[ii].name} ${value[ii].query};  `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n         ffb:${value[ii].name} ${value[ii].query};.  `;
                this.queryqb40AllTampung = value[ii].key;
                this.queryqb4oAllTime.push(value[ii].key);
              } else if (value[ii].key == `?fcpKey`) {
                this.queryincrementFcp = `${this.queryincrementFcp} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n         ffb:${value[ii].name} ${value[ii].query};.  `;
                this.queryqb40AllTampung = value[ii].key;
                this.queryqb4oAllTime.push(value[ii].key);
              } else if (value[ii].key == `?plantationKey`) {
                this.queryincrementFcp = `${this.queryincrementFcp} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n         ffb:${value[ii].name} ${value[ii].query};.  `;
                this.queryqb40AllTampung = value[ii].key;
                this.queryqb4oAllTime.push(value[ii].key);
              } else if (value[ii].key == `?truckKey`) {
                this.queryincrementFcp = `${this.queryincrementFcp} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n         ffb:${value[ii].name} ${value[ii].query};.  `;
                this.queryqb40AllTampung = value[ii].key;
                this.queryqb4oAllTime.push(value[ii].key);
              }

              this.dimatributgabung = `${this.dimatributgabung} ${value[ii].query}`;
              break;
            }
          }
          if (this.dimensinkeyTampung != value[ii].key) {
            if (this.dimatributTampung[ii] == value[ii].query) {
              continue;
            }
            if (this.dimatributTampung[ii] != value[ii].query) {
              this.dimatributTampung.push(value[ii].query);
              this.dimatributgabung = `${this.dimatributgabung} ${value[ii].query}`;
              this.dimensionkeygabung = `${this.dimensionkeygabung} ${value[ii].key}`;

              if (value[ii].key == `?afdelingKey`) {
                this.queryDimensionffb = `${this.queryDimensionffb}\n     ffb:afdelingKey ${value[ii].key}; `;
                this.queryqb4oAfd = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
                this.queryincrementAfd = `${this.queryincrementAfd} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n     ${this.queryqb4oAfd}\n        ${this.queryincrementAfd} `;
                this.queryqb40AllTampung = value[ii].key;
                // this.queryqb4oAllAfd.push(value[ii].key);
              } else if (value[ii].key == `?blockKey`) {
                this.queryDimensionffb = `${this.queryDimensionffb}\n     ffb:blockKey ${value[ii].key}; `;
                this.queryqb4oBlck = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
                this.queryincrementBlck = `${this.queryincrementBlck} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n     ${this.queryqb4oBlck}\n        ${this.queryincrementBlck} `;
                this.queryqb40AllTampung = value[ii].key;
                // this.queryqb4oAllBlck.push(value[ii].key);
              } else if (value[ii].key == `?timeKey`) {
                this.queryDimensionffb = `${this.queryDimensionffb}\n     ffb:dateKey ${value[ii].key}; `;
                this.queryqb4oTime = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
                this.queryincrementTime = `${this.queryincrementTime} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n     ${this.queryqb4oTime}\n        ${this.queryincrementTime} `;
                this.queryqb40AllTampung = value[ii].key;
              } else if (value[ii].key == `?fcpKey`) {
                this.queryDimensionffb = `${this.queryDimensionffb}\n     ffb:fcpKey ${value[ii].key};`;
                this.queryqb4oFcp = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
                this.queryincrementFcp = `${this.queryincrementFcp} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n     ${this.queryqb4oFcp}\n        ${this.queryincrementFcp} `;
                this.queryqb40AllTampung = value[ii].key;
              } else if (value[ii].key == `?plantationKey`) {
                this.queryDimensionffb = `${this.queryDimensionffb}\n     ffb:plantationKey ${value[ii].key};`;
                this.queryqb4oPlant = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
                this.queryincrementPlant = `${this.queryincrementPlant} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n     ${this.queryqb4oPlant}\n        ${this.queryincrementPlant} `;
                this.queryqb40AllTampung = value[ii].key;
              } else if (value[ii].key == `?truckKey`) {
                this.queryDimensionffb = `${this.queryDimensionffb}\n     ffb:truckKey ${value[ii].key};`;
                this.queryqb4oTruck = `${value[ii].key} qb4o:memberOf ffb:${value[ii].dimensioname}; `;
                this.queryincrementTruck = `${this.queryincrementTruck} ffb:${value[ii].name} ${value[ii].query}; `;
                this.queryqb40AllGabung = `${this.queryqb40AllGabung}\n     ${this.queryqb4oTruck}\n        ${this.queryincrementTruck} `;
                this.queryqb40AllTampung = value[ii].key;
              }

              this.dimensionkeyTampung = value[ii].key;
              break;
            }
          }
        }
        if (this.arrdimensionkey.length <= 0) {
          this.arrdimensionkey.push(this.dimensionkeygabung);
        } else {
          this.arrdimensionkey.splice(0, this.arrdimensionkey.length);
          this.arrdimensionkey.push(this.dimensionkeygabung);
        }

        if (this.querydimensiongabung.length <= 0) {
          this.querydimensiongabung.push(this.queryDimensionffb);
        } else {
          this.querydimensiongabung.splice(0, this.querydimensiongabung.length);
          this.querydimensiongabung.push(this.queryDimensionffb);
        }
        /////////////////////////////////////// push QueryQB4 ///////////////////////////////////////////
        if (this.queryqb4ogabungAfd.length <= 0) {
          this.queryqb4ogabungAfd.push(this.queryqb4oAfd);
        } else {
          this.queryqb4ogabungAfd.splice(0, this.queryqb4ogabungAfd.length);
          this.queryqb4ogabungAfd.push(this.queryqb4oAfd);
        }

        if (this.queryqb4ogabungBlck.length <= 0) {
          this.queryqb4ogabungBlck.push(this.queryqb4oBlck);
        } else {
          this.queryqb4ogabungBlck.splice(0, this.queryqb4ogabungBlck.length);
          this.queryqb4ogabungBlck.push(this.queryqb4oBlck);
        }

        if (this.queryqb4ogabungTime.length <= 0) {
          this.queryqb4ogabungTime.push(this.queryqb4oTime);
        } else {
          this.queryqb4ogabungTime.splice(0, this.queryqb4ogabungTime.length);
          this.queryqb4ogabungTime.push(this.queryqb4oTime);
        }

        if (this.queryqb4ogabungFcp.length <= 0) {
          this.queryqb4ogabungFcp.push(this.queryqb4oFcp);
        } else {
          this.queryqb4ogabungFcp.splice(0, this.queryqb4ogabungFcp.length);
          this.queryqb4ogabungFcp.push(this.queryqb4oFcp);
        }

        if (this.queryqb4ogabungPlant.length <= 0) {
          this.queryqb4ogabungPlant.push(this.queryqb4oPlant);
        } else {
          this.queryqb4ogabungPlant.splice(0, this.queryqb4ogabungPlant.length);
          this.queryqb4ogabungPlant.push(this.queryqb4oPlant);
        }

        if (this.queryqb4ogabungTruck.length <= 0) {
          this.queryqb4ogabungTruck.push(this.queryqb4oTruck);
        } else {
          this.queryqb4ogabungTruck.splice(0, this.queryqb4ogabungTruck.length);
          this.queryqb4ogabungTruck.push(this.queryqb4oTruck);
        }
        //////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////// push QueryIincrement /////////////////////////////////
        if (this.arrqueryincrementgabungAfd.length <= 0) {
          this.arrqueryincrementgabungAfd.push(this.queryincrementAfd);
        } else {
          this.arrqueryincrementgabungAfd.splice(
            0,
            this.arrqueryincrementgabungAfd.length
          );
          this.arrqueryincrementgabungAfd.push(this.queryincrementAfd);
        }

        if (this.arrqueryincrementgabungBlck.length <= 0) {
          this.arrqueryincrementgabungBlck.push(this.queryincrementBlck);
        } else {
          this.arrqueryincrementgabungBlck.splice(
            0,
            this.arrqueryincrementgabungBlck.length
          );
          this.arrqueryincrementgabungBlck.push(this.queryincrementBlck);
        }

        if (this.arrqueryincrementgabungTime.length <= 0) {
          this.arrqueryincrementgabungTime.push(this.queryincrementTime);
        } else {
          this.arrqueryincrementgabungTime.splice(
            0,
            this.arrqueryincrementgabungTime.length
          );
          this.arrqueryincrementgabungTime.push(this.queryincrementTime);
        }

        if (this.arrqueryincrementgabungFcp.length <= 0) {
          this.arrqueryincrementgabungFcp.push(this.queryincrementFcp);
        } else {
          this.arrqueryincrementgabungFcp.splice(
            0,
            this.arrqueryincrementgabungFcp.length
          );
          this.arrqueryincrementgabungFcp.push(this.queryincrementFcp);
        }

        if (this.arrqueryincrementgabungPlant.length <= 0) {
          this.arrqueryincrementgabungPlant.push(this.queryincrementPlant);
        } else {
          this.arrqueryincrementgabungPlant.splice(
            0,
            this.arrqueryincrementgabungPlant.length
          );
          this.arrqueryincrementgabungPlant.push(this.queryincrementPlant);
        }

        if (this.arrqueryincrementgabungTruck.length <= 0) {
          this.arrqueryincrementgabungTruck.push(this.queryincrementTruck);
        } else {
          this.arrqueryincrementgabungTruck.splice(
            0,
            this.arrqueryincrementgabungTruck.length
          );
          this.arrqueryincrementgabungTruck.push(this.queryincrementTruck);
        }
        ////////////////////////////////////////////////////////////////////////////////////////////

        if (this.arrdimatribut.length <= 0) {
          this.arrdimatribut.push(this.dimatributgabung);
        } else {
          this.arrdimatribut.splice(0, this.arrdimatribut.length);
          this.arrdimatribut.push(this.dimatributgabung);
        }

        if (this.arrqueryqb4oAll.length <= 0) {
          if (this.queryqb40AllTampung == "?afdelingKey") {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          }
          if (this.queryqb40AllTampung == "?blockKey") {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          }
          if (this.queryqb40AllTampung == "?timeKey") {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          }
          if (this.queryqb40AllTampung == "?fcpKey") {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          }
          if (this.queryqb40AllTampung == "?plantationKey") {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          }
          if (this.queryqb40AllTampung == "?truckKey") {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          }
        } else {
          this.arrqueryqb4oAll.splice(0, this.arrqueryqb4oAll.length);
          if (
            this.queryqb40AllTampung == "?afdelingKey" &&
            this.queryqb4oAllAfd.length <= 0
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          } else if (
            this.queryqb40AllTampung == "?afdelingKey" &&
            this.queryqb4oAllAfd.length >= 1
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung);
          }

          if (
            this.queryqb40AllTampung == "?blockKey" &&
            this.queryqb4oAllBlck.length <= 0
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          } else if (
            this.queryqb40AllTampung == "?blockKey" &&
            this.queryqb4oAllBlck.length >= 1
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung);
          }

          if (
            this.queryqb40AllTampung == "?timeKey" &&
            this.queryqb4oAllTime.length <= 0
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          } else if (
            this.queryqb40AllTampung == "?timeKey" &&
            this.queryqb4oAllTime.length >= 1
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung);
          }

          if (
            this.queryqb40AllTampung == "?fcpKey" &&
            this.queryqb4oAllTime.length <= 0
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          } else if (
            this.queryqb40AllTampung == "?fcpKey" &&
            this.queryqb4oAllTime.length >= 1
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung);
          }

          if (
            this.queryqb40AllTampung == "?plantationKey" &&
            this.queryqb4oAllTime.length <= 0
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          } else if (
            this.queryqb40AllTampung == "?plantationKey" &&
            this.queryqb4oAllTime.length >= 1
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung);
          }

          if (
            this.queryqb40AllTampung == "?truckKey" &&
            this.queryqb4oAllTime.length <= 0
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          } else if (
            this.queryqb40AllTampung == "?truckKey" &&
            this.queryqb4oAllTime.length >= 1
          ) {
            this.arrqueryqb4oAll.push(this.queryqb40AllGabung);
          }

          // if(this.queryqb40AllTampung == "?block_key" || this.queryqb4oAllBlck != ""){
          //   this.arrqueryqb4oAll.push(this.queryqb40AllGabung + ".");
          // }else{
          //   this.arrqueryqb4oAll.push(this.queryqb40AllGabung);
          // }
        }
        this.sectionSkrg = "dimension";
        this.queryAll();
      }
    },

    //////// agg function //////////
    getActiveValue2(value) {
      if (value.length > 0) {
        if (this.tampungfunction == ``) {
          for (var i = 0; i < value.length; i++) {
            for (var j = 0; j < this.tampungmeasureskrg.length; j++) {
              if (value[i].name == this.tampungmeasureskrg[j]) {
                this.tampungfunction2 = value[i].key;
                if (this.tampungmeasureskrg[j] == "?load") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];
                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(${value[i].key}(${value[i].name}) as ?loadku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
                if (this.tampungmeasureskrg[j] == "?droute") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];
                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(${value[i].key}(${value[i].name}) as ?drouteku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
                if (this.tampungmeasureskrg[j] == "?trajectory") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];
                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(${value[i].key}(${value[i].name}) as ?trajectoryku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
                if (this.tampungmeasureskrg[j] == "?distance") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];
                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(${value[i].key}(${value[i].name}) as ?distanceku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
                if (this.tampungmeasureskrg[j] == "?duration") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];
                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(${value[i].key}(${value[i].name}) as ?durationku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);

                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
              }
            }
          }
        } else {
          for (var ij = 0; ij < value.length; ij++) {
            if (this.tampungfunctionIncrement == value[ij].name) {
              for (
                var y = 0;
                y < this.tampungmeasureskrgForFunction.length;
                y++
              ) {
                if (value[ij].name == this.tampungmeasureskrgForFunction[y]) {
                  if (this.tampungmeasureskrgForFunction[y] == "?load") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${value[ij].key}(jsfn:${this.tampungfunction}(${this.tampungmeasureskrgForFunction[y]})) as ?loadku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                  if (this.tampungmeasureskrgForFunction[y] == "?droute") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${value[ij].key}(jsfn:${this.tampungfunction}(${this.tampungmeasureskrgForFunction[y]})) as ?drouteku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                  if (this.tampungmeasureskrgForFunction[y] == "?trajectory") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${value[ij].key}(jsfn:${this.tampungfunction}(${this.tampungmeasureskrgForFunction[y]})) as ?trajectoryku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                  if (this.tampungmeasureskrgForFunction[y] == "?distance") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${value[ij].key}(jsfn:${this.tampungfunction}(${this.tampungmeasureskrgForFunction[y]})) as ?distanceku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                  if (this.tampungmeasureskrgForFunction[y] == "?duration") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${value[ij].key}(jsfn:${this.tampungfunction}(${this.tampungmeasureskrgForFunction[y]})) as ?durationku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                }
              }
            }
          }
        }
        this.oldFunction = true;
        this.newFunction = false;
        if (this.arrdimensionkey.length > 0) {
          this.sectionSkrg = "dimension";
        } else {
          this.sectionSkrg = "measure";
        }
        this.queryAll();
      }
    },

    /// mob function //////////
    getActiveValue3(value) {
      if (value.length > 0) {
        if (this.tampungfunction2 == ``) {
          for (var i = 0; i < value.length; i++) {
            for (var j = 0; j < this.tampungmeasureskrg.length; j++) {
              if (value[i].name == this.tampungmeasureskrg[j]) {
                this.tampungfunction = value[i].key;
                if (this.tampungmeasureskrg[j] == "?load") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];
                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(jsfn:${value[i].key}(${value[i].name}) as ?loadku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
                if (this.tampungmeasureskrg[j] == "?droute") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];

                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(jsfn:${value[i].key}(${value[i].name}) as ?drouteku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
                if (this.tampungmeasureskrg[j] == "?trajectory") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];
                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(jsfn:${value[i].key}(${value[i].name}) as ?trajectoryku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
                if (this.tampungmeasureskrg[j] == "?distance") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];

                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(jsfn:${value[i].key}(${value[i].name}) as ?distanceku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
                if (this.tampungmeasureskrg[j] == "?duration") {
                  this.tampungfunctionIncrement = this.tampungmeasureskrg[j];
                  this.tampungText = `${
                    this.tampungText
                  } ${this.tampungmeasureskrg[j].replace(
                    this.tampungmeasureskrg[j],
                    `(jsfn:${value[i].key}(${value[i].name}) as ?durationku) `
                  )}`;
                  this.tampungmeasureskrg.splice(j, 1);
                  this.tampungmeasureskrggabungbaru =
                    this.tampungmeasureskrg.join(" ");
                  this.measureTempt.splice(0, this.measureTempt.length);
                  this.measureTempt.push(
                    this.tampungText + this.tampungmeasureskrggabungbaru
                  );
                  break;
                }
              }
            }
          }
        } else {
          for (var ij = 0; ij < value.length; ij++) {
            if (this.tampungfunctionIncrement == value[ij].name) {
              for (
                var y = 0;
                y < this.tampungmeasureskrgForFunction.length;
                y++
              ) {
                if (value[ij].name == this.tampungmeasureskrgForFunction[y]) {
                  if (this.tampungmeasureskrgForFunction[y] == "?load") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${this.tampungfunction2}(jsfn:${value[ij].key}(${this.tampungmeasureskrgForFunction[y]})) as ?loadku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                  if (this.tampungmeasureskrgForFunction[y] == "?droute") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${this.tampungfunction2}(jsfn:${value[ij].key}(${this.tampungmeasureskrgForFunction[y]})) as ?drouteku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                  if (this.tampungmeasureskrgForFunction[y] == "?trajectory") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${this.tampungfunction2}(jsfn:${value[ij].key}(${this.tampungmeasureskrgForFunction[y]})) as ?trajectoryku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                  if (this.tampungmeasureskrgForFunction[y] == "?distance") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${this.tampungfunction2}(jsfn:${value[ij].key}(${this.tampungmeasureskrgForFunction[y]})) as ?distanceku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                  if (this.tampungmeasureskrgForFunction[y] == "?duration") {
                    this.tampungTextNew = `${
                      this.tampungTextNew
                    } ${this.tampungmeasureskrgForFunction[y].replace(
                      this.tampungmeasureskrgForFunction[y],
                      `(${this.tampungfunction2}(jsfn:${value[ij].key}(${this.tampungmeasureskrgForFunction[y]})) as ?durationku) `
                    )}`;
                    this.tampungmeasureskrgForFunction.splice(y, 1);
                    this.tampungmeasureskrggabungbaru =
                      this.tampungmeasureskrgForFunction.join(" ");
                    this.measureTempt.splice(0, this.measureTempt.length);
                    this.measureTempt.push(
                      this.tampungTextNew + this.tampungmeasureskrggabungbaru
                    );
                    break;
                  }
                }
              }
            }
          }
        }
        console.log(this.newFunction);
        this.newFunction = true;
        if (this.oldFunction === true) {
          this.newFunction = false;
        }
        if (this.arrdimensionkey.length > 0) {
          this.sectionSkrg = "dimension";
          this.queryAll();
        } else {
          this.sectionSkrg = "measure";
          this.queryAll();
        }
      }
    },

    makeKoordQuery() {
      if (this.status == "created") {
        if (this.filter != "") {
          let query = `FILTER (geof:sfWithin(${this.tampungGeo},"${this.json}"))`;
          this.filter = query;
          this.queryAll();
        } else {
          this.content = "";
          let query = `PREFIX jsfn: <http://www.ontotext.com/js#>\nPREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\nPREFIX qb: <http://purl.org/linked-data/cube#>\nPREFIX ffb: <http://www.uajy.ac.id/ffbi/>\nPREFIX qb4o: <http://www.w3.org/2001/XMLSchema#>\nPREFIX geof: <http://www.opengis.net/def/function/geosparql/>\nPREFIX ffbi: <http://www.uajy.ac.id/ffbi/instance#>\n`;
          query += `SELECT\n?oName\n?afdelingGeo\n?plantationGeo\n `;
          query += `WHERE{\n`;
          query += `?obs rdf:type qb:Observation ;\n`
          query += `      ffb:truckKey ?truckKey ;\n`
          query += `      ffb:dateKey ?dateKey ;\n`
          query += `      ffb:Route ?droute ;\n`
          query += `      ffb:plantationKey ?plantationKey .\n`
          query += `?dateKey qb4o:memberOf ffb:date;\n`
          query += `      ffb:quarter ?quarter .\n`
          query += `?plantationKey qb4o:memberOf ffb:plantation ;\n`
          query += `      ffb:plantationGeo ?plantationGeo .\n`
          query += `?afdelingKey #qb4o:memberOf ffb:afdeling ;\n`
          query += `      ffb:afdelingGeo ?afdelingGeo ;\n`
          query += `      ffb:afdelingName 'Afdeling Alfa' .\n`
          query += `?truckKey #qb4o:memberOf ffb:Truck;\n`
          query += `      ffb:ownerName ?oName .\n`
          query += `FILTER (geof:sfWithin(?afdelingGeo,"${this.json}"))\n`
          query += `} `
          this.content = query;
        }
      }
      if (this.status == "edited") {
        if (this.filter != "") {
          this.filter = ``;
          let query = `FILTER (geof:sfWithin(${this.tampungGeo},"${this.json}"))`;
          this.filter = query;
          this.queryAll();
        } else {
          this.content = "";
          let query = `PREFIX jsfn: <http://www.ontotext.com/js#>\nPREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\nPREFIX qb: <http://purl.org/linked-data/cube#>\nPREFIX ffb: <http://www.uajy.ac.id/ffbi/>\nPREFIX qb4o: <http://www.w3.org/2001/XMLSchema#>\nPREFIX geof: <http://www.opengis.net/def/function/geosparql/>\nPREFIX ffbi: <http://www.uajy.ac.id/ffbi/instance#>\n`;
          query += `SELECT\n?oName\n?afdelingGeo\n?plantationGeo\n `;
          query += `WHERE{\n`;
          query += `?obs rdf:type qb:Observation ;\n`
          query += `      ffb:truckKey ?truckKey ;\n`
          query += `      ffb:dateKey ?dateKey ;\n`
          query += `      ffb:Route ?droute ;\n`
          query += `      ffb:plantationKey ?plantationKey .\n`
          query += `?dateKey qb4o:memberOf ffb:date;\n`
          query += `      ffb:quarter ?quarter .\n`
          query += `?plantationKey qb4o:memberOf ffb:plantation ;\n`
          query += `      ffb:plantationGeo ?plantationGeo .\n`
          query += `?afdelingKey #qb4o:memberOf ffb:afdeling ;\n`
          query += `      ffb:afdelingGeo ?afdelingGeo ;\n`
          query += `      ffb:afdelingName 'Afdeling Alfa' .\n`
          query += `?truckKey #qb4o:memberOf ffb:Truck;\n`
          query += `      ffb:ownerName ?oName .\n`
          query += `FILTER (geof:sfWithin(?afdelingGeo,"${this.json}"))\n`
          query += `} `
          this.content = query;
        }
      }
      if (this.status == "deleted") {
        if (this.filter != "") {
          this.filter = ``;
          let query = `FILTER (geof:sfWithin(${this.tampungGeo},""))`;
          this.filter = query;
          this.queryAll();
        } else {
          this.content = "";
        }
      }
    },

    getSpatialFunction(value) {
      if (value.length != 0) {
        if (this.tampungGeo != "") {
          let query = `FILTER (geof:sfWithin(${this.tampungGeo},"${this.json}"))`;
          this.filter = query;
        } else {
          let query = `FILTER (geof:sfWithin(,"${this.json}"))`;
          this.filter = query;
          // this.sectionSkrg = "measure";
        }
        this.queryAll();
      }
    },
  },

  mounted() {
    this.$refs.map.mapObject.on("draw:created",function (e) {
        const layer = e.layer;
        let polygonStr = "POLYGON(("; // note the double parentheses for the loop
        layer.getLatLngs()[0].forEach((coord, index) => {
          polygonStr += `${coord.lat} ${coord.lng}`; // note the order of lng and lat
          if (index < layer.getLatLngs()[0].length - 1) {
            polygonStr += ", ";
          }
        });
        polygonStr +=
          ", " +
          layer.getLatLngs()[0][0].lat +
          " " +
          layer.getLatLngs()[0][0].lng +
          "))"; // add the first coordinate to close the loop
        this.json = polygonStr;
        this.status = "created";
        this.$vuetify.goTo("#genSparql");
        this.makeKoordQuery();
      }.bind(this)
    );

    this.$refs.map.mapObject.on("draw:edited", (e) => {
      const layers = e.layers;
      layers.eachLayer((layer) => {
        let polygonStr = "POLYGON((";
        layer.getLatLngs()[0].forEach((coord, index) => {
          polygonStr += `${coord.lat} ${coord.lng}`;
          if (index < layer.getLatLngs()[0].length - 1) {
            polygonStr += ", ";
          }
        });
        polygonStr += ", " + layer.getLatLngs()[0][0].lat + " " + layer.getLatLngs()[0][0].lng + "))"; // add the first coordinate to close the loop
        this.json = polygonStr;
      });
      this.status = "edited";
      this.$vuetify.goTo("#genSparql");
      this.makeKoordQuery();
    });

    this.$refs.map.mapObject.on("draw:deleted", (e) => {
      const layers = e.layers;
      layers.eachLayer((layer) => {
        this.$refs.map.mapObject.removeLayer(layer);
      });
      this.json = null;
      this.status = "deleted";
      this.$vuetify.goTo("#genSparql");
      this.makeKoordQuery();
    });

    this.readMarker();
    this.readKoorDistribusi();
    this.readAfdeling();
    this.readBlok();

    this.queryAll();
    // this.getActiveValue();

    this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo({ lat: -7.7612784, lng: 110.4246399 });
    });
  },
};
</script>
<style scoped>
.v-textarea .v-model {
  text-align: left;
}
.map {
  height: 600px;
}
</style>
  